/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import DefaultLayout from './../layouts/Default'
import config from './../data/SiteConfig'
import SEO from '../components/Seo'

/**
 * The terms and conditions page.
 */
class TermsAndConditionsPage extends Component {
  render () {
    return (
      <DefaultLayout>
        <SEO
          pageType={`webpage`}
          pageTitle={`Terms & Conditions`}
          fullPageTitle={`Terms and Conditions`}
          pageDescription={`Keith-mifsud.me Terms of Use`}
          pageImage={config.siteImage}
          pagePath={`/terms-and-conditions`}
        />
        <div className="container legal-page">
          <div className="row pt-lg-12 pt-8 justify-content-center">
            <section className="col-12 col-lg-8">
              <h1>Terms and Conditions</h1>
            </section>
            <div className="col-12 col-lg-8 pt-lg-4">
              <p className={`pb-lg-4`}><strong>Last updated: 17th September 2019</strong></p>
              <h2>Use of website with these conditions</h2>
              <p>
                All use of the website https://keith-mifsud.me ('the Site') is on the terms and conditions below.
              </p>
              <p>
                If you do not agree to these conditions cease use of the Site immediately.
              </p>
              <p>
                You may print and keep a copy of these terms. They are a legal agreement between us and can only be
                modified with our consent. We reserve the right to change the terms at our discretion by changing them
                on the website.
              </p>
              <h2>
                Accuracy of information and disclaimer
              </h2>
              <p>
                Keith Mifsud and his associates (‘we') do our best to ensure all information on the Site is accurate.
              </p>
              <p>
                If you find any inaccurate information on the Site let us know and we will correct it, where we agree,
                as soon as practicable.
              </p>
              <p>
                You should ensure information you send to us is accurate and does not breach anyone else's rights such
                as copyright or is libellous, obscene, menacing, threatening, offensive, abusive, fraudulent, criminal
                or infringes the rights of other people or in is in any way illegal. Remember that the Site can be
                viewed around the world and any information you send to us may be published on the Site.
              </p>
              <p>
                You should independently verify any information before relying upon it.
              </p>
              <p>
                We make no representations that information is accurate and up to date or complete and accept no
                liability for any loss or damage caused by inaccurate information. This Site gives a large amount of
                data and there will inevitably be errors in it
              </p>
              <p>
                Although we hope this Site will be of interest to users, we accept no liability and offer no warranties
                in relation to it and its content, to the fullest extent such liability can be excluded by law.</p>
              <h2>
                Copyright on the site
              </h2>
              <p>
                Unless otherwise indicated the Site contains copyright material, trade names and other proprietary information, including, but not limited to, text, software, photos, graphics and may in future include video, graphics, music and sound. The entire contents of the Site are protected by copyright law. We, or our licensors, own copyright and/or database right in the selection, co-ordination, arrangement and enhancement of such content, as well as in the content original to it. You may not modify, publish, transmit, participate in the transfer or sale, create derivative works, or in any way exploit, any of the content, in whole or in part except as provided in these Terms
              </p>
              <p>
                You may download information from the Site for your own personal use only. Except as otherwise expressly permitted under copyright law, no copying, redistribution, retransmission, publication or commercial exploitation of downloaded material will be permitted without our express permission and that of the copyright owner. In the event of any permitted copying, redistribution or publication of copyright material, no changes in or deletion of author attribution, trademark legend or copyright notice shall be made. You acknowledge that you do not acquire any ownership rights by downloading copyright material.
              </p>
              <p>
                We provide hypertext links to other sites are operated by other people. Using such a link means you are leaving our Site and we take no responsibility for, and give no warranties, guarantees or representations in respect of linked sites.
              </p>
              <h2>Our liability</h2>
              <p>
                We provide most information on this Site free of any access charge. Where not charged for the information provided on the Site is provided on the basis of no liability for the information given. Where we provide a chargeable service to you we accept liability for direct loss arising from our not having used reasonable skill and care in the provision of such services up to the price you have paid us in any year, in the year of claim.
              </p>
              <p>
                In no event shall we be liable to you for, in the case of non-chargeable services any direct, and for both chargeable and non-chargeable services any indirect or consequential loss, loss of profit, revenue or good will arising from your use of the Site or information on the Site. Subject as provided below, all terms implied by law are excluded.
              </p>
              <p>
                We accept liability for death or personal injury caused by negligence or responsibility for fraudulent misrepresentation that cannot, under English law, be excluded.
              </p>
              <p>
                The information on the Site is not intended to address your particular requirements. Such information does not constitute any form of advice or recommendation by us and is not intended to be relied upon by you in making (or refraining from making) any specific investment, or other, decisions. You should take your own advice.
              </p>
              <p>
                If you make an arrangement with anyone named or connection with the Site this is at your sole risk.
              </p>
              <h2>
                Barring from the site
              </h2>
              <p>
                We reserve the right to bar users from the Site, on a permanent or temporary basis at our discretion. Any such user shall be notified and must not then attempt to use the Site under any other name or through any other user.
              </p>
              <h2>
                Legal jurisdiction and dispute resolution
              </h2>
              <p>
                English law shall apply to these terms, notwithstanding the jurisdiction where you are based. You irrevocably agree that the courts of England shall have exclusive jurisdiction to settle any dispute which may arise out of, under, or in connection with these Terms and for those purposes irrevocably submit all disputes to the jurisdiction of the English courts. The place of performance shall be England.
              </p>
              <h2>
                General
              </h2>
              <p>
                Any formal legal notices should be sent to us at the address at the end of these Terms by email.
              </p>
              <p>Failure by us to enforce a right does not result in waiver of such right.</p>
              <p>You may not assign or transfer your rights under this agreement.</p>
              <p>Nothing in these Terms is intended to, nor shall it, confer any benefit on a third party whether under the Contracts (Rights of Third Parties) Act 1999 or otherwise.</p>
              <p>We may amend these Terms at any time by posting a variation on the Site.</p>
              <h2>
                Further information
              </h2>
              <p>Further information on these conditions or any queries on them can be obtained from:</p>
              <p><strong>mifsud.k@gmail.com</strong></p>
            </div>
          </div>

        </div>
      </DefaultLayout>
    )
  }
}

export default TermsAndConditionsPage
